@font-face {
    font-family: 'JZY';
    font-style: normal;
    src: local('JZY'), url('./market/assets/JZY.ttf') format('truetype');
}

@font-face {
    font-family: 'MILanProVF';
    font-style: normal;
    src: local('MILanProVF'), url('./market/assets/MILanProVF.ttf') format('truetype');
}

@font-face {
    font-family: 'MILanting';
    font-style: normal;
    src: local('MILanting'), url('./market/assets/MILanting.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    src: local('Raleway'), url('./market/assets/Raleway.ttf') format('truetype');
}


/* @font-face {
  font-family: 'RalewayBold';
  font-style: normal;
  src: local('RalewayBold'),
    url('./market/assets/RalewayBold.ttf') format('truetype');
} */

@font-face {
    font-family: 'ZhuoJianGanLanJianTi';
    font-style: normal;
    src: local('ZhuoJianGanLanJianTi'), url('./market/assets/ZhuoJianGanLanJianTi.ttf') format('truetype');
}

* {
    font-family: PingFangSC-Regular, sans-serif;
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 100%;
}

a {
    text-decoration: none;
    color: #404040;
    font-size: 14px;
}

a.active {
    color: #999;
}

p {
    font-size: 14px;
}

li {
    list-style-type: none;
}

h2 {
    font-size: 28px;
}

h1 {
    font-size: 52px;
}

.subPageRoot {
    display: flex;
    flex-direction: column;
    margin-top: 220px;
}

.subPageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}

.subPageTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.subPageTitle h1 {
    font-size: 48px;
    font-family: 'Raleway', sans-serif;
    color: #1f497d;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-bottom: 30px;
    text-align: center;
}

.subPageTitle h1::before {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 84%;
    max-width: 180px;
    height: 6px;
    background: #1f74d4;
}

.subPageTitle div {
    /* font-weight: normal;
    font-family: JZY, sans-serif;
    font-size: 32px;
    line-height: 1.3;
    color: #1f497d; */
    width: 300px;
    height: 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-bottom: 30px;
}

.subPageTitle div::before {
    content: '';
    position: absolute;
    bottom: -20px;
    width: 84%;
    max-width: 180px;
    height: 6px;
    background: #1f74d4;
}

.subPagePara {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Noto Sans SC', sans-serif;
    color: #1f497d;
    margin-bottom: 6px;
    text-align: center;
}

.subPageTitle h2 {
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    font-size: 36x;
    line-height: 1.3;
    color: #3a3a3a;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.subPageTitle h2::before {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 120%;
    max-width: 100px;
    height: 6px;
    background: #1f74d4;
}

.aboutUsTitle {
    font-size: 28px;
    text-align: center;
}

@media screen and (max-width: 720px) {
    .subPageTitle h1 {
        font-size: 22px;
    }
    .subPageTitle h2 {
        font-size: 17px;
    }
    .subPagePara {
        font-size: 16px;
    }
    h2 {
        font-size: 16px;
    }
    .subPageRoot {
        margin-top: 100px;
    }
    .aboutUsTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .subPageTitle div {
        width: 300px;
        height: 25px;
    }
    .subPageTitle h1 {
        width: 90%;
        font-size: 22px;
        margin-bottom: 10px;
    }
    .subPageTitle h2 {
        font-size: 20px;
    }
    .subPagePara {
        font-size: 12px;
    }
    .subPageTitle h1::before {
        max-width: 100px;
        margin-bottom: 10px;
        height: 3px;
    }
    .subPageTitle h2::before {
        height: 3px;
    }
    .aboutUsTitle {
        font-size: 16px;
    }
}