.MuiInputBase-root {
  width: 90%;
}
.MuiDialog-paper {
  border: 7px solid #5195cd;
  border-radius: 20px;
  height: 20vw;
  width: 16vw;
}
#alert-dialog-thank {
  color: #5195cd;
  font-size: 27px;
  line-height: 0.91;
  letter-spacing: 2.35px;
}
#alert-dialog-description {
  width: 70%;
  font-size: 16px;
  text-align: center;
}
.MuiInputBase-input.MuiInput-input {
  padding: 7px 0 7px;
}
@media screen and (max-width: 1650px) {
  .MuiDialog-paper {
    border: 7px solid #5195cd;
    height: 35vw;
    width: 30vw;
  }
}
@media screen and (max-width: 1400px) {
  .MuiDialog-paper {
    border: 7px solid #5195cd;
    height: 40vw;
    width: 35vw;
  }
}
@media screen and (max-width: 1200px) {
  #alert-dialog-thank {
    color: #5195cd;
    font-size: 30px;
    line-height: 1.91;
    letter-spacing: 3.35px;
  }
  #alert-dialog-description {
    font-size: 18px;
  }
}
@media screen and (max-width: 1000px) {
  .MuiDialog-paper {
    border: 7px solid #5195cd;
    height: 50vw;
    width: 45vw;
  }
}
